/* eslint-disable import/prefer-default-export */
import classNames from '@/utils/className';
import { ComponentPropsWithoutRef } from 'react';

const variants = {
  PageWrapper: {
    Ads: {
      true: 'mt-[100px] [@media_only_screen_and_(min-width:426px)_and_(max-width:827px)]:mt-[31.22vw] [@media_only_screen_and_(min-width:828px)]:mt-[250px]',
      false: '',
    },
    isAmp: {
      true: 'pb-0',
      false: '',
    },
  },
} as const;

export const PageWrapper = ({ Ads, isAmp, children, ...rest }: ComponentPropsWithoutRef<'main'> & { Ads?: boolean; isAmp?: boolean }) => {
  return (
    <main
      className={classNames(
        'relative mx-auto my-0 block max-w-[1000px] items-center pb-[51px] phone:pb-0 extraLargeDesktop:max-w-[calc(1000px+100vw-1360px)] [@media_only_screen_and_(min-width:1960px)]:max-w-[1600px]',
        Ads != undefined && variants.PageWrapper.Ads[Ads.toString()],
        isAmp != undefined && variants.PageWrapper.isAmp[isAmp.toString()]
      )}
      {...rest}>
      {children}
    </main>
  );
};
