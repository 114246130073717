import { useState } from 'react';
import useAmpComponent from '@/hooks/useAmpComponent';
import { getCurrentHub } from '@sentry/nextjs';
import AdvertorialTag from '@/components/Internals/Header/AdvertorialTag';
import useBreakpoint from '@/hooks/useBreakpoint';
import AlertMessage from '../ShareButton/AlertMessage';
import { mglGray } from '@/styles/designSystemColors';
import classNames from '@/utils/className';

export type ShareButtonProps = {
  title: string;
  description: string;
  url: string;
  isAdvertorial?: boolean;
};

type ObjectButtonInternal = {
  name: string;
  link?: string;
  icon: 'whatsapp' | 'telegram' | 'linkedin' | 'facebook' | 'twitter' | 'link-variant' | 'email';
  bgColor: string;
  linkApp?: string;
  copyClipboard?: boolean;
  message?: string;
  show: boolean;
  position: number;
};

export default function InternalShareButton({ title, description, url, isAdvertorial = false }: ShareButtonProps) {
  const currentSpan = getCurrentHub().getScope()?.getSpan();
  const internalShareButtonSpan = currentSpan && currentSpan.startChild({ op: 'internalShareButton' });

  const { Icon } = useAmpComponent();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [shouldShowMessage, setShouldShowMessage] = useState(false);

  const InternalBtnList: ObjectButtonInternal[] = [
    {
      name: 'Whatsapp',
      link: `https://web.whatsapp.com/send?text=${encodeURIComponent(title)} ${url}`,
      icon: 'whatsapp',
      bgColor: '#25d366',
      linkApp: `whatsapp://send?text=${encodeURIComponent(title)} ${url}`,
      show: isMenuOpen,
      position: 6,
    },
    {
      name: 'Telegram',
      link: `https://telegram.me/share/url?url=${url}&text=${encodeURIComponent(title)} ${url}`,
      icon: 'telegram',
      bgColor: '#1d98dc',
      linkApp: `tg://msg?url=${url}&text=${encodeURIComponent(title)} ${url}`,
      show: isMenuOpen,
      position: 5,
    },
    {
      name: 'Linkedin',
      link: `https://www.linkedin.com/shareArticle?url=${url}`,
      icon: 'linkedin',
      bgColor: '#0077b5',
      show: isMenuOpen,
      position: 3,
    },
    {
      name: 'Facebook',
      link: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
      icon: 'facebook',
      bgColor: '#3b5998',
      show: isMenuOpen,
      position: 4,
    },
    {
      name: 'Twitter',
      link: `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${url}`,
      icon: 'twitter',
      bgColor: '#55acee',
      show: isMenuOpen,
      position: 2,
    },
    {
      name: 'Link',
      link: url,
      icon: 'link-variant',
      bgColor: '#999',
      message: 'Link copiado!',
      copyClipboard: true,
      show: isMenuOpen,
      position: 1,
    },
    {
      name: 'E-mail',
      link: `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(description)}%0D%0A%0D%0A Veja o conteúdo completo em ${url}`,
      icon: 'email',
      bgColor: mglGray.mglGray900,
      show: isMenuOpen,
      position: 0,
    },
  ];

  const [isTabletOrMobile] = useBreakpoint('tablet');

  const showAlertMessage = () => {
    setShouldShowMessage(true);
    const intervalshow = setInterval(() => {
      setShouldShowMessage(false);
      clearInterval(intervalshow);
    }, 3000);
  };

  const getCorrectLink = (btnProps: ObjectButtonInternal) => {
    let link: string;
    if (isTabletOrMobile && (btnProps.name === 'Whatsapp' || btnProps.name === 'Telegram')) {
      link = btnProps.linkApp;
    } else if (btnProps.copyClipboard) {
      link = '';
    } else {
      link = btnProps.link;
    }
    return link;
  };

  return (
    <div className="mb-8 flex flex-col flex-wrap justify-between gap-3 tablet:flex-row">
      <div className="relative flex">
        <div className="inline-flex flex-col items-center justify-center gap-4 rounded bg-mglGray50 transition-all duration-300 ease-ease">
          <div className="flex flex-wrap justify-center gap-3 tablet:flex-nowrap tablet:justify-normal">
            {InternalBtnList.map(internalBtnProps => {
              const Tag = internalBtnProps.name === 'Link' ? 'button' : 'a';
              let linkProps = {};
              if (getCorrectLink(internalBtnProps)) {
                linkProps = {
                  href: getCorrectLink(internalBtnProps),
                  target: '_blank',
                  rel: 'noreferrer',
                };
              }
              return (
                <Tag
                  id={internalBtnProps.name}
                  className="share-button-click flex h-8 w-8 items-center justify-center rounded-half"
                  style={{ backgroundColor: internalBtnProps.bgColor, border: 0 }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...linkProps}
                  onClick={e => {
                    if (internalBtnProps.copyClipboard) {
                      e.preventDefault();
                      navigator.clipboard.writeText(internalBtnProps.link);
                      showAlertMessage();
                    }
                    setIsMenuOpen(false);
                  }}
                  key={internalBtnProps.position}
                  title={`Compartilhar com ${internalBtnProps.name}`}>
                  <div className={classNames('flex items-center justify-center text-2xl', internalBtnProps.name === 'Link' && 'share-button-click')}>
                    <Icon
                      {...(internalBtnProps.name === 'Link' && { id: 'LinkSVG' })}
                      icon={internalBtnProps.icon === 'telegram' ? 'cib:telegram-plane' : `mdi:${internalBtnProps.icon}`}
                      width={22}
                      height={22}
                      color={mglGray.mglGray50}
                      viewBox={internalBtnProps.icon === 'telegram' ? '0 0 32 32' : '0 0 24 24'}
                      {...(internalBtnProps.name === 'Link' && { className: 'share-button-click' })}
                    />
                  </div>
                </Tag>
              );
            })}
          </div>
        </div>
      </div>
      <AlertMessage showAlert={shouldShowMessage} />
      {isAdvertorial && <AdvertorialTag />}
      {internalShareButtonSpan && internalShareButtonSpan.finish()}
    </div>
  );
}
