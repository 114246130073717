/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import { useAmp } from 'next/amp';
import parse, { attributesToProps } from 'html-react-parser';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import { ElementType, Fragment, HTMLAttributes } from 'react';
import render from 'dom-serializer';
import Link from 'next/link';
import getEnv from '@/utils/getEnv';
import isValidHttpUrl from '@/utils/isValidHttpUrl';
import CustomComponentComposer, { isCustomComponent } from '../CustomComponentComposer';
import { TableContainer } from '../NewsContent/styles';

export interface ComponentProps extends HTMLAttributes<HTMLOrSVGElement> {
  as?: ElementType;
  tipo: string;
  atributos?: { nome: string; valor: string }[];
  valor?: (string | ComponentProps)[];
  target?: string;
  url?: string;
  rel?: string;
  [x: string]: any;
}

export function ContentComposer({
  as: Tag = Fragment,
  tipo,
  valor = undefined,
  url = undefined,
  target = undefined,
  rel = undefined,
  atributos = undefined,
  ...otherProps
}: ComponentProps) {
  const ClientSideParseHtml = dynamic(() => import('@/components/Internals/ClientSideParseHtml'), { ssr: false });

  const isAmp = useAmp();

  const options = {
    // eslint-disable-next-line consistent-return
    replace: domNode => {
      if (domNode.attribs && domNode.name === 'blockquote' && domNode.children.find(e => e.name === 'p' || e.type === 'text')) {
        const domNodeAlter = domNode;
        domNodeAlter.attribs.className = 'blockquote';
        const stringDom = render(domNodeAlter);
        return <>{parse(stringDom)}</>;
      }
      if (domNode.attribs && (domNode.name === 'blockquote' || domNode.name === 'iframe')) {
        if (!domNode.children.find(e => e.name === 'p')) {
          return <ClientSideParseHtml domNode={domNode} />;
        }
      }
      if (domNode.attribs && domNode.name === 'script') {
        const telegramProps = { 'data-telegram-post': domNode.attribs?.['data-telegram-post'] || '', 'data-width': domNode.attribs?.['data-width'] || '' };
        return <Script async={domNode.attribs.async} src={domNode.attribs.src} strategy="afterInteractive" {...telegramProps} />;
      }
    },
  };

  const parseAmpIframe = (value: string) => {
    if (value.startsWith('<iframe') && isAmp) {
      return parse(
        `<amp-iframe layout="responsive" width="160" height="90" src=${value
          .split(' ')
          .find(string => string.startsWith('src'))
          .replace('src=', '')}></amp-iframe>`,
        options
      );
    }

    return parse(value, options);
  };

  const elementParser = (type: string) => {
    let ContainerTag: ElementType = Fragment;
    let ParentTag: ElementType = Fragment;
    let GrandparentTag: ElementType = Fragment;
    let linkProps = {};
    let nextLinkProps = {};
    const attrProps = {};

    if (type === 'link') {
      let isInternalLink = false;
      if (isValidHttpUrl(url)) {
        const { origin } = new URL(url);
        isInternalLink = origin === getEnv('URL');
      }
      ParentTag = 'a';
      linkProps = { href: url, rel, target };

      if (isInternalLink) {
        GrandparentTag = Link;
        Object.assign(nextLinkProps, { href: url, prefetch: false, passHref: true });
        Object.assign(linkProps, { target: '_self' });
      }
    }
    if (type === 'lista') ContainerTag = 'li';
    if (type === 'tabela') ParentTag = TableContainer;

    if (atributos) {
      atributos.forEach(attr => {
        Object.assign(attrProps, { [attr.nome]: attr.valor });
      });
    }

    const element = valor?.map((child, index) => {
      if (typeof child === 'string')
        return (
          <ContainerTag {...otherProps} {...(ContainerTag !== Fragment && attributesToProps(attrProps))} key={index}>
            {child}
          </ContainerTag>
        );

      if (isCustomComponent(child))
        return (
          <ContainerTag {...otherProps} {...(ContainerTag !== Fragment && attributesToProps(attrProps))} key={index}>
            <CustomComponentComposer partHtml={child} index={index} key={index} />
          </ContainerTag>
        );

      return (
        <ContainerTag {...otherProps} key={index}>
          <ContentComposer
            as={child?.tag}
            tipo={child?.tipo}
            valor={child?.valor}
            url={child?.url}
            target={child?.target}
            rel={child?.rel}
            atributos={child?.atributos}
          />
        </ContainerTag>
      );
    });

    return (
      <GrandparentTag {...nextLinkProps}>
        <ParentTag {...linkProps}>
          <Tag {...otherProps} {...(Tag !== Fragment && attributesToProps(attrProps))}>
            {element}
          </Tag>
        </ParentTag>
      </GrandparentTag>
    );
  };

  return <>{tipo !== 'html' ? elementParser(tipo) : <>{typeof valor?.[0] === 'string' && parseAmpIframe(valor?.[0])}</>}</>;
}

export default ContentComposer;
