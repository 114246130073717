import classNames from '@/utils/className';

type AlertMessageProps = {
  showAlert: boolean;
};

export default function AlertMessage({ showAlert }: AlertMessageProps) {
  const variants = {
    AlertContainer: {
      visible: { true: 'scale-100 opacity-100 right-6', false: '[scale(0.6)] opacity-50 right-[-130px]' },
    },
  };
  return (
    <div
      className={classNames(
        'fixed right-6 top-16 z-[996] flex content-center items-center justify-center bg-mglGray800 transition-all ease-in-out tablet:top-6',
        variants.AlertContainer.visible[showAlert.toString()]
      )}>
      <p className="p-2 text-mglGray200">Link copiado!</p>
    </div>
  );
}
